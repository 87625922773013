//type
import { STATUS, OCCUPANCY } from '@interfaces/graphql/API';
import { ProspectRowProps } from '@interfaces/prospects/helpers/module/prospectsTable/helpers/ProspectRow';

//Library
import React, { ReactElement, useEffect, useRef, useState } from 'react';
import Link from 'next/link';

//Code
import Status from '@components/prospects/helpers/layout/Status';
import Timer from '@components/prospects/helpers/layout/Timer';
import CollapsibleContent from '@components/prospects/helpers/module/prospectsTable/helpers/CollapsibleContent';
import timeframe from '@components/helpers/list/timeframe';
import propertyType from '@components/helpers/list/type';
import occupancy from '@components/helpers/list/occupancy';

/**
 * Prospects Table row.
 *
 * @param {ProspectRowProps} props Row props.
 * @returns {ReactElement} - The propsect row.
 */
const ProspectRow: React.FC<ProspectRowProps> = ({
    prospect,
    openPopup
}: ProspectRowProps): ReactElement => {
    const [quoteStatus, setQuoteStatus] = useState<STATUS>(prospect.status);

    const [showHidden, setShowHidden] = useState(false);
    const content = useRef<HTMLDivElement>(null);

    /** Show the remark and documents. */
    const toggleOpen = () => {
        setShowHidden(!showHidden);
    };

    useEffect(() => {
        setQuoteStatus(prospect.status);
    }, [prospect.status]);

    return (
        <>
            <div className="border-b border-athens-gray">
                <div
                    aria-label="collapsible-button"
                    onClick={toggleOpen}
                    onKeyDown={toggleOpen}
                    role="button"
                    tabIndex={0}
                    className="hover:bg-gray-100 py-2 flex">
                    <span
                        className={`flex items-center transition-transform duration-500 ease-in-out transform ${
                            showHidden ? 'rotate-0' : '-rotate-90'
                        }`}>
                        <svg
                            className="stroke-current"
                            width="16"
                            height="9"
                            viewBox="0 0 16 9"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M15 1L8 8L1 1"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                    </span>
                    {/* prospect details */}
                    <div className="grid place-items-stretch grid-cols-24 py-2 text-sm font-bold flex-grow">
                        <div className="col-span-4 px-2 flex items-center">{prospect.address}</div>
                        <div className="col-span-3 px-2 flex items-center">
                            {timeframe[prospect.timeframe]}
                        </div>
                        <div className="col-span-3 px-2 flex items-center">
                            {propertyType[prospect.type]}
                        </div>
                        <div className="col-span-4 px-2 flex items-center">
                            {prospect.occupancy ? (
                                occupancy[prospect.occupancy] + ' '
                            ) : (
                                <>&mdash;</>
                            )}{' '}
                            {prospect.occupancy == OCCUPANCY.MOVING_IN
                                ? prospect.expected_date
                                : null}
                        </div>
                        <div className="col-span-4 px-2 flex items-center">
                            <div className="w-full  shrink lg:flex-none">
                                {quoteStatus == STATUS.PENDING_QUOTE ||
                                quoteStatus == STATUS.TIMEOUT ? (
                                    <Timer
                                        expiry={prospect.delivery}
                                        onExpire={() => {
                                            setQuoteStatus(STATUS.TIMEOUT);
                                        }}
                                    />
                                ) : (
                                    <Status status={quoteStatus} />
                                )}
                            </div>
                        </div>
                        {/* buttons */}
                        <div className="col-span-6 px-2 flex justify-end items-center">
                            <div className="mr-3">
                                {quoteStatus == STATUS.TIMEOUT || quoteStatus == STATUS.REJECTED ? (
                                    <button
                                        className="w-40 px-7 rounded-md h-9 text-xs bg-gray-200 text-gray-400"
                                        disabled={true}
                                        aria-label="disabled quote">
                                        Proceed to Quote
                                    </button>
                                ) : (
                                    <Link
                                        href={{
                                            pathname: '/quote/[id]'
                                        }}
                                        as={{
                                            pathname: `/quote/${encodeURIComponent(prospect.id)}`
                                        }}
                                        passHref>
                                        <a
                                            href="#"
                                            className="w-40 flex items-center justify-center px-7 h-9 rounded-md  text-xs bg-elements text-white"
                                            aria-label="quote"
                                            onClick={(e) => e.stopPropagation()}>
                                            {quoteStatus == STATUS.PENDING_QUOTE ||
                                            quoteStatus == STATUS.PENDING_REQUOTE ? (
                                                <>Proceed to Quote</>
                                            ) : (
                                                <>See Quote</>
                                            )}
                                        </a>
                                    </Link>
                                )}
                            </div>
                            <button
                                className={`rounded-md h-9 text-xs px-7 ${
                                    quoteStatus !== STATUS.PENDING_QUOTE
                                        ? 'bg-gray-200 text-gray-400'
                                        : 'bg-sai-red text-white'
                                }`}
                                disabled={quoteStatus !== STATUS.PENDING_QUOTE}
                                onClick={openPopup}
                                aria-label="refuse-prospect">
                                Reject
                            </button>
                        </div>
                    </div>
                </div>
                {/* remark and documents (hidden) */}
                <div
                    ref={content}
                    aria-label="collapsible-content"
                    className={`overflow-hidden -mr-4 transition-all duration-200 ease-linear`}
                    style={{
                        maxHeight: showHidden
                            ? Number(content.current && content.current.scrollHeight)
                            : 0
                    }}>
                    <CollapsibleContent
                        docs={prospect.docs}
                        remark={prospect.remark}
                        comment={prospect.comment}
                    />
                </div>
            </div>
        </>
    );
};

export default ProspectRow;
