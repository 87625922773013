//type
import { ProspectsTableProps } from '@interfaces/prospects/helpers/module/prospectsTable/ProspectTable';

//Library
import React, { Fragment, ReactElement } from 'react';

//Code
import OrderMenu from '@components/prospects/helpers/module/prospectsTable/helpers/OrderMenu';
import ProspectRow from '@components/prospects/helpers/module/prospectsTable/helpers/ProspectRow';

/**
 * Prospects Table View.
 *
 * @param {ProspectsTableProps} props Table props.
 * @returns {ReactElement} - The Prospects Table View.
 */
const ProspectsTable: React.FC<ProspectsTableProps> = ({
    prospects,
    order,
    setOrder,
    openPopup
}: ProspectsTableProps): ReactElement => {
    return (
        <div className="text-navy">
            {prospects.length ? <OrderMenu order={order} setOrder={setOrder} /> : null}
            {prospects.map((prospect, index) => (
                <Fragment key={index}>
                    <ProspectRow prospect={prospect} openPopup={openPopup(index)} />
                </Fragment>
            ))}
        </div>
    );
};

export default ProspectsTable;
