//type
import { RejectPopupProps } from '@interfaces/prospects/helpers/layout/RejectPopup';

//lib
import React, { ReactElement } from 'react';
import * as Yup from 'yup';

//code
import PopUp from '@components/layout/PopUp';
import { ErrorMessage, Field, Form, Formik } from 'formik';

/**
 * Creates the confirm popup when updating a request.
 *
 * @param {RejectPopupProps} props - The props
 * @returns {ReactElement} - The confirm popup
 */
const RejectPopup = ({ open, closeModal, onConfirm, error }: RejectPopupProps): ReactElement => {
    const initialValues = {
        comment: ''
    };

    return (
        <PopUp
            open={open}
            closeModal={closeModal}
            title={'Are you sure you want to reject this prospect?'}>
            <div className="mt-5">
                <div className="text-red-500 text-center mt-4">{error}</div>
                <Formik
                    initialValues={initialValues}
                    validationSchema={Yup.object({
                        comment: Yup.string()
                            .min(2, 'Must be 2 characters or more')
                            .required('Required')
                    })}
                    onSubmit={(values) => {
                        onConfirm(values.comment);
                    }}
                    enableReinitialize>
                    <Form name="reject" className="lg:w-full">
                        <div aria-label="reject-form">
                            <label htmlFor="comment">
                                Please let us know why in the comments below :
                            </label>
                            <Field
                                aria-label="comment"
                                className="w-full mt-2 py-1 p-2 border border-inputBorder rounded"
                                name="comment"
                                component="textarea"
                                rows="3"
                            />
                            <div className="text-red-500 text-xs italic ">
                                <ErrorMessage name="comment" />
                            </div>
                        </div>
                        <div className="flex justify-between mt-2">
                            <button
                                type="button"
                                className="rounded-md bg-elements  text-white p-2 px-5 w-28"
                                aria-label="exit"
                                onClick={closeModal}>
                                Exit
                            </button>
                            <button
                                type="submit"
                                className="ml-2 rounded-md bg-sai-red text-white py-2 px-5 w-28"
                                aria-label="confirm">
                                Confirm
                            </button>
                        </div>
                    </Form>
                </Formik>
            </div>
        </PopUp>
    );
};

export default RejectPopup;
