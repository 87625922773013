//type
import {
    listProspectsDataProps,
    listProspectsDataReturn,
    ListProspectsQuery
} from '@interfaces/prospects/helpers/query/listProspectData';

import { ListProspectsQueryVariables } from '@interfaces/graphql/generated/API';

// library
import { GraphQLResult } from '@aws-amplify/api';

//code
import { listProspects } from '@components/graphql/queries';

/**
 * Get the prospects data
 *
 * @param {listProspectsDataProps} props - The Api object we want to call
 * @returns {listProspectsDataReturn} - The data
 */
const listProspectsData = async ({
    API,
    query
}: listProspectsDataProps): Promise<listProspectsDataReturn> => {
    const { sort_field = null, sort = null } = query;

    const variables: ListProspectsQueryVariables = {
        ...query,
        sort_field,
        sort
    };
    const response: GraphQLResult<ListProspectsQuery> = (await API.graphql({
        query: listProspects,
        variables
    })) as GraphQLResult<ListProspectsQuery>;

    return (
        response.data?.listProspects || {
            __typename: 'ListProspects',
            total: 0,
            prospects: []
        }
    );
};

export default listProspectsData;
