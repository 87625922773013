//Library
import React, { ReactElement } from 'react';
import Head from 'next/head';

//code
import Header from '@components/layout/Header';
import Prospects from '@components/prospects/Prospects';
import { useRouter } from 'next/router';

/**
 * The home page.
 *
 * @returns {ReactElement} - The index page.
 */
const Home: React.FC = (): ReactElement => {
    const router = useRouter();

    return (
        <>
            <Head>
                <title>Solar AI: Search</title>
                <link rel="icon" href="/favicon.ico" />
            </Head>
            <Header />
            <Prospects router={router} />
        </>
    );
};

export default Home;
