//type
import { PROSPECT_SORT_FIELD } from '@interfaces/graphql/API';
import { OrderMenuProps } from '@interfaces/prospects/helpers/module/prospectsTable/helpers/OrderMenu';

//library
import React, { ReactElement } from 'react';

//code
import SortButton from '@components/prospects/helpers/module/prospectsTable/helpers/SortButton';

/**
 * Table menu for sorting.
 *
 * @param {OrderMenuProps} props - Props.
 * @returns {ReactElement} - The order menu.
 */
const OrderMenu: React.FC<OrderMenuProps> = ({ order, setOrder }: OrderMenuProps): ReactElement => {
    const fields = [
        { name: 'Address', sortField: PROSPECT_SORT_FIELD.ADDRESS, colSpan: 'col-span-4' },
        { name: 'Timeframe', sortField: PROSPECT_SORT_FIELD.TIME_FRAME, colSpan: 'col-span-3' },
        { name: 'Type', sortField: PROSPECT_SORT_FIELD.TYPE, colSpan: 'col-span-3' },
        {
            name: 'Est. Move-in Date',
            sortField: PROSPECT_SORT_FIELD.OCCUPANCY,
            colSpan: 'col-span-4'
        },
        { name: 'Status', sortField: PROSPECT_SORT_FIELD.STATUS, colSpan: 'col-span-4' }
    ];

    return (
        <div className={`z-10 grid bg-alice-blue px-4 text-left w-full grid-cols-24 border-b`}>
            {fields.map((field) => (
                <div key={field.name} className={`${field.colSpan}  border-athens-gray px-2`}>
                    <SortButton
                        name={field.name}
                        field={field.sortField}
                        order={order}
                        setOrder={setOrder}
                    />
                </div>
            ))}
        </div>
    );
};

export default OrderMenu;
