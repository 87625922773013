//type
import { VIEW } from '@interfaces/graphql/API';
import { MenuProps, STATUS } from '@interfaces/prospects/helpers/layout/Menu';

//Library
import React, { ReactElement } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

//Code
import SearchInput from '@components/prospects/helpers/layout/SearchInput';

/**
 * The status menu.
 *
 * @param {MenuProps} props - The props
 * @returns {ReactElement} - The status menu.
 */
const Request: React.FC<MenuProps> = ({
    fetch,
    status,
    isTableView,
    changeView,
    setUrl,
    query
}: MenuProps): ReactElement => {
    const initialValues = {
        search_term: query.search_term || ''
    };

    return (
        <div className="fixed w-full z-10 border-b px-6 flex justify-between bg-primary py-3">
            <div className="flex flex-wrap">
                <button
                    aria-label="all"
                    onClick={() => fetch()}
                    className={`rounded-full bg-white text-navy px-3 py-2 text-xs h-8 m-1 ${
                        !status && 'shadow-lg'
                    }`}>
                    All
                </button>
                <button
                    aria-label="quote pending"
                    onClick={() => fetch(STATUS.PENDING_QUOTE)}
                    className={`rounded-full bg-safety-orange-100 text-safety-orange px-3 py-2 text-xs h-8 m-1 ${
                        status == STATUS.PENDING_QUOTE && 'shadow-lg'
                    }`}>
                    Pending Quotes
                </button>
                <button
                    aria-label="requote pending"
                    onClick={() => fetch(STATUS.PENDING_REQUOTE)}
                    className={`rounded-full bg-deep-green-100 text-deep-green px-3 py-2 text-xs h-8 m-1 ${
                        status == STATUS.PENDING_REQUOTE && 'shadow-lg'
                    }`}>
                    Pending Re-quote
                </button>
                <button
                    aria-label="review"
                    onClick={() => fetch(STATUS.REVIEW)}
                    className={`rounded-full bg-azure-100 text-azure px-3 py-2 text-xs h-8 mr-3 m-1 ${
                        status == STATUS.REVIEW && 'shadow-lg'
                    }`}>
                    In Review
                </button>
                <button
                    aria-label="proposal sent"
                    onClick={() => fetch(STATUS.PROPOSAL_SEND)}
                    className={`rounded-full bg-cadet-100 text-cadet px-3 py-2 text-xs h-8 mr-3 m-1 ${
                        status == STATUS.PROPOSAL_SEND && 'shadow-lg'
                    }`}>
                    Proposal Sent
                </button>
                <button
                    aria-label="shortlisted"
                    onClick={() => fetch(STATUS.SHORTLISTED)}
                    className={`rounded-full bg-pale-fushia-100 text-pale-fushia  px-3 py-2 text-xs h-8 mr-3 m-1 ${
                        status == STATUS.SHORTLISTED && 'shadow-lg'
                    }`}>
                    Shortlisted
                </button>
                <button
                    aria-label="won"
                    onClick={() => fetch(STATUS.CLOSE_WON)}
                    className={`rounded-full bg-lime-100 text-lime px-3 py-2 text-xs h-8 mr-3 m-1 ${
                        status == STATUS.CLOSE_WON && 'shadow-lg'
                    }`}>
                    Closed Won
                </button>
                <button
                    aria-label="lost"
                    onClick={() => fetch(STATUS.CLOSE_LOST)}
                    className={`rounded-full bg-lavender-100 text-lavender px-3 py-2 text-xs h-8 mr-3 m-1 ${
                        status == STATUS.CLOSE_LOST && 'shadow-lg'
                    }`}>
                    Closed Lost
                </button>
                <button
                    aria-label="nurture"
                    onClick={() => fetch(STATUS.NURTURE)}
                    className={`rounded-full bg-rajah-100 text-rajah px-3 py-2 text-xs h-8 mr-3 m-1 ${
                        status == STATUS.NURTURE && 'shadow-lg'
                    }`}>
                    Nurture
                </button>
                <button
                    aria-label="rejected"
                    onClick={() => fetch(STATUS.REJECTED)}
                    className={`rounded-full bg-camel-100 text-camel px-3 py-2 text-xs h-8 mr-3 m-1 ${
                        status == STATUS.REJECTED && 'shadow-lg'
                    }`}>
                    Rejected
                </button>
                <button
                    aria-label="timeout"
                    onClick={() => fetch(STATUS.TIMEOUT)}
                    className={`rounded-full bg-cadet-100 text-cadet px-3 py-2 text-xs h-8 mr-3 m-1 ${
                        status == STATUS.TIMEOUT && 'shadow-lg'
                    }`}>
                    Expired
                </button>
            </div>

            <div className="flex mr-4">
                <div className="mt-1 px-3">
                    <Formik
                        initialValues={initialValues}
                        validationSchema={Yup.object({
                            search_term: Yup.string()
                        })}
                        enableReinitialize
                        onSubmit={(values) => {
                            setUrl({ ...query, ...values });
                        }}>
                        <Form>
                            <SearchInput field="search_term" setUrl={setUrl} />
                        </Form>
                    </Formik>
                </div>
                {isTableView ? (
                    <button
                        aria-label="switch to grid"
                        onClick={changeView(VIEW.GRID)}
                        className="hidden lg:flex lg:items-center text-light-blue self-center rounded-md py-2  gap-2 justify-end group relative px-2 ">
                        <svg
                            width="26"
                            height="26"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M9 3H4C3.44772 3 3 3.44772 3 4V9C3 9.55228 3.44772 10 4 10H9C9.55228 10 10 9.55228 10 9V4C10 3.44772 9.55228 3 9 3Z"
                                stroke="#29618D"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M9 14H4C3.44772 14 3 14.4477 3 15V20C3 20.5523 3.44772 21 4 21H9C9.55228 21 10 20.5523 10 20V15C10 14.4477 9.55228 14 9 14Z"
                                stroke="#29618D"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M20 3H15C14.4477 3 14 3.44772 14 4V9C14 9.55228 14.4477 10 15 10H20C20.5523 10 21 9.55228 21 9V4C21 3.44772 20.5523 3 20 3Z"
                                stroke="#29618D"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M20 14H15C14.4477 14 14 14.4477 14 15V20C14 20.5523 14.4477 21 15 21H20C20.5523 21 21 20.5523 21 20V15C21 14.4477 20.5523 14 20 14Z"
                                stroke="#29618D"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                    </button>
                ) : (
                    <button
                        onClick={changeView(VIEW.TABLE)}
                        aria-label="switch to table"
                        className="hidden lg:flex lg:items-center text-light-blue self-center rounded-md py-2 gap-2 justify-end group relative px-2">
                        <svg
                            width="23"
                            height="23"
                            viewBox="0 0 21 21"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M0 2.625C0 1.92881 0.276562 1.26113 0.768845 0.768845C1.26113 0.276562 1.92881 0 2.625 0L18.375 0C19.0712 0 19.7389 0.276562 20.2312 0.768845C20.7234 1.26113 21 1.92881 21 2.625V18.375C21 19.0712 20.7234 19.7389 20.2312 20.2312C19.7389 20.7234 19.0712 21 18.375 21H2.625C1.92881 21 1.26113 20.7234 0.768845 20.2312C0.276562 19.7389 0 19.0712 0 18.375V2.625ZM19.6875 5.25H14.4375V9.1875H19.6875V5.25ZM19.6875 10.5H14.4375V14.4375H19.6875V10.5ZM19.6875 15.75H14.4375V19.6875H18.375C18.7231 19.6875 19.0569 19.5492 19.3031 19.3031C19.5492 19.0569 19.6875 18.7231 19.6875 18.375V15.75ZM13.125 19.6875V15.75H7.875V19.6875H13.125ZM6.5625 19.6875V15.75H1.3125V18.375C1.3125 18.7231 1.45078 19.0569 1.69692 19.3031C1.94306 19.5492 2.2769 19.6875 2.625 19.6875H6.5625ZM1.3125 14.4375H6.5625V10.5H1.3125V14.4375ZM1.3125 9.1875H6.5625V5.25H1.3125V9.1875ZM7.875 5.25V9.1875H13.125V5.25H7.875ZM13.125 10.5H7.875V14.4375H13.125V10.5Z"
                                fill="#29618D"
                            />
                        </svg>
                    </button>
                )}
            </div>
        </div>
    );
};

export default Request;
