//type
import { CollapsibleContentProps } from '@interfaces/prospects/helpers/module/prospectsTable/helpers/CollapsibleContent';

//library
import React, { ReactElement } from 'react';

//code
import ProspectsDocs from '@components/layout/ProspectsDocs';

/**
 * Shows the collapsible content for prospect row.
 *
 * @param {CollapsibleContentProps} props Collapsible content props.
 * @returns {ReactElement} - The collapsible content.
 */
const CollapsibleContent: React.FC<CollapsibleContentProps> = ({
    remark,
    comment,
    docs
}: CollapsibleContentProps) => {
    return (
        <div className="flex py-2 px-4 text-sm font-bold text-gray-400 mb-4 hover:bg-gray-100">
            <div className="col-span-4 px-2 w-5/12">
                <div className="mb-2">Remark:</div>
                <div className="text-navy">{remark ? remark : <>&mdash;</>}</div>
                <div className="mt-4 mb-2">Special Considerations:</div>
                <div className="text-navy">{comment ? comment : <>&mdash;</>}</div>
            </div>
            {docs && (
                <div className=" ml-3">
                    Documents:
                    <div className="flex gap-7">
                        <ProspectsDocs docs={docs} />
                    </div>
                </div>
            )}
        </div>
    );
};

export default CollapsibleContent;
