//type
import { PopupProps } from '@interfaces/layout/Popup';

//Library
import React, { ReactElement } from 'react';
import { Popup } from 'reactjs-popup';

/**
 * Create ta pop-up
 *
 * @param {PopupProps} props - The popup prop
 * @returns {ReactElement} - The popup
 */
const PopUp: React.FC<PopupProps> = ({
    open,
    closeModal,
    children,
    title
}: PopupProps): ReactElement => {
    /* Close the modal */
    return (
        <Popup
            open={Boolean(open)}
            closeOnDocumentClick
            onClose={closeModal}
            overlayStyle={{
                position: 'fixed',
                top: '0',
                backdropFilter: 'blur(10px)',
                background: 'rgba(0, 0, 0, 0.20)',
                overflowY: 'scroll'
            }}
            contentStyle={{
                margin: 'auto',
                width: '60%',
                maxWidth: '1400px',
                minWidth: '300px'
            }}
            lockScroll>
            <div>
                <div className="flex justify-end">
                    <button
                        type="button"
                        aria-label="close"
                        className="rounded-full bg-white flex items-center px-3 py-1 font-black"
                        onClick={closeModal}>
                        <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M12 4L4 12"
                                stroke="#374554"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M4 4L12 12"
                                stroke="#374554"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>{' '}
                        Close
                    </button>
                </div>
                <div className="rounded-lg bg-white mt-3 p-6">
                    <h1 className="text-saiblack font-bold text-xl">{title}</h1>
                    <div>{children}</div>
                </div>
            </div>
        </Popup>
    );
};

export default PopUp;
