//type
import { TimerProps } from '@interfaces/prospects/helpers/layout/Timer';

//Library
import React, { ReactElement } from 'react';
import { useTimer } from 'react-timer-hook';

/**
 * Create the request card
 *
 * @param {TimerProps} props - The props
 * @returns {ReactElement} - The Timer card
 */
const Timer: React.FC<TimerProps> = ({ expiry, onExpire }: TimerProps): ReactElement => {
    const expiryTimestamp = new Date(expiry);

    const { seconds, minutes, hours, days } = useTimer({
        expiryTimestamp,
        onExpire
    });

    return (
        <div className="rounded-full bg-safety-orange-100 text-safety-orange px-3 py-2 text-xs h-8 flex items-center">
            {!days && !hours && !minutes && !seconds ? (
                <>
                    <svg
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                        className="stroke-current">
                        <path
                            d="M5.72253 10.3995L8.46453 8.72775V5.2395"
                            strokeWidth="1.2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M8.25002 15C6.94688 15.0005 5.67146 14.6237 4.57774 13.9152C3.48402 13.2067 2.61876 12.1968 2.08644 11.0074C1.55411 9.8179 1.37746 8.49978 1.57783 7.21214C1.7782 5.92449 2.34701 4.72238 3.21559 3.75091C4.08418 2.77945 5.2154 2.08018 6.47268 1.73753C7.72996 1.39487 9.05956 1.42348 10.3009 1.8199C11.5423 2.21632 12.6424 2.9636 13.4684 3.97153C14.2944 4.97945 14.811 6.20493 14.9558 7.5"
                            strokeWidth="1.2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M15 15.75H12C11.6022 15.75 11.2206 15.592 10.9393 15.3107C10.658 15.0294 10.5 14.6478 10.5 14.25V11.25C10.5 10.8522 10.658 10.4706 10.9393 10.1893C11.2206 9.90804 11.6022 9.75 12 9.75H15C15.3978 9.75 15.7794 9.90804 16.0607 10.1893C16.342 10.4706 16.5 10.8522 16.5 11.25V14.25C16.5 14.6478 16.342 15.0294 16.0607 15.3107C15.7794 15.592 15.3978 15.75 15 15.75Z"
                            strokeWidth="1.2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M14.25 12L12.75 13.5"
                            strokeWidth="1.2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M12.75 12L14.25 13.5"
                            strokeWidth="1.2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                    <span className="pl-1">Timed Out</span>
                </>
            ) : (
                <>
                    <span aria-label="expiring">
                        Pending - Expiring in {Boolean(days) && `${days} days and`}{' '}
                        {hours.toString().length > 1 ? hours : `0${hours}`}:
                        {minutes.toString().length > 1 ? minutes : `0${minutes}`}:
                        {seconds.toString().length > 1 ? seconds : `0${seconds}`}
                    </span>
                </>
            )}
        </div>
    );
};

export default Timer;
