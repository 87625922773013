//Type
import {
    LoadProspects,
    useLoadProspectsReturn,
    FetchPage,
    Reset,
    useLoadProspectsProps
} from '@interfaces/prospects/helpers/query/useLoadProspects';
import { Prospect, PROSPECT_SORT_FIELD, SORT, STATUS, VIEW } from '@interfaces/graphql/API';
import { listProspectsDataReturn } from '@interfaces/prospects/helpers';
import { SortState } from '@interfaces/prospects';

//Library
import { useEffect, useState } from 'react';
import { API } from 'aws-amplify';
import { useRouter } from 'next/router';
import { ParsedUrlQueryInput, ParsedUrlQuery } from 'querystring';

//Code
import listProspectsData from '@components/prospects/helpers/query/listProspectData';
import listProspectsDataAndView from '@components/prospects/helpers/query/listProspectAndView';

/**
 * Load more on the front end.
 *
 * @param {useLoadProspectsProps} props - The props.
 * @returns {useLoadProspectsReturn} - The functions
 */
const useLoadProspects = ({
    query,
    countryCode
}: useLoadProspectsProps): useLoadProspectsReturn => {
    const router = useRouter();

    const [prospects, setProspects] = useState<Prospect[]>([]);
    const [hasMoreProspects, sethasMoreProspects] = useState<boolean>(true);
    const [page, setPage] = useState<number>(0);
    const [fetch, setFetch] = useState<boolean>(false);
    const [total, setTotal] = useState<number | null>(null);
    const [view, setView] = useState<VIEW>(VIEW.TABLE);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const size = 10;

    const order: SortState = [
        query.sort_field as PROSPECT_SORT_FIELD | undefined,
        query.sort as SORT | undefined
    ];

    /**
     * Fetch new data.
     *
     * @param {STATUS} status - The new status.
     */
    const fetchLocationsPage: FetchPage = async (status?: STATUS): Promise<void> => {
        const data: listProspectsDataReturn = await listProspectsData({
            API,
            query: {
                ...query,
                size,
                from: size * page,
                status,
                country_code: countryCode
            }
        });

        if (!data.prospects.length) {
            sethasMoreProspects(false);
            setTotal(null);
            return;
        }

        setProspects([...prospects, ...data.prospects]);
        setTotal(data.total);
        setPage(page + 1);
    };

    /**
     * Fetch prospects and Epc preferred view (used for first load).
     *
     * @param {STATUS} status - Status of prospects to fetch.
     */
    const fetchProspectsAndView = async (status?: STATUS): Promise<void> => {
        const { listProspects, getProfile } = await listProspectsDataAndView({
            API,
            query: {
                ...query,
                size,
                from: size * page,
                status,
                country_code: countryCode
            }
        });

        setView(getProfile.view);

        if (!listProspects.prospects.length) {
            sethasMoreProspects(false);
            setTotal(null);
            return;
        }

        setProspects([...prospects, ...listProspects.prospects]);
        setTotal(listProspects.total);
        setPage(page + 1);
    };

    /**
     * Fetch Data.
     *
     * @param {STATUS} status - Status of prospects to fetch.
     * @returns {Promise<void>} Void.
     */
    const fetchPage = async (status?: STATUS): Promise<void> => {
        if (prospects.length) {
            return await fetchLocationsPage(status);
        }

        await fetchProspectsAndView(status);
    };

    /**
     * Load more location for infinite scroll.
     *
     * @param {STATUS} status - The status we search
     */
    const loadProspects: LoadProspects = async (status?: STATUS): Promise<void> => {
        if (total == prospects.length) {
            sethasMoreProspects(false);
            return;
        }
        if (fetch) {
            return;
        }

        setFetch(true);
        await fetchPage(status);
        setFetch(false);
    };

    /**
     * Sets the new URL from the new query.
     *
     * @param {ParsedUrlQuery} query The new query.
     */
    const setUrl = (query?: ParsedUrlQuery) => {
        const urlQuery: ParsedUrlQueryInput = !query ? {} : { ...query };
        const filteredQuery = Object.fromEntries(
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            Object.entries(urlQuery).filter(([k, v]) => Boolean(v))
        );
        router.push({ pathname: router.pathname, query: filteredQuery });
    };

    /**
     * Sets the query.
     *
     * @param {SortState} order - The new sorting variables.
     */
    const setOrder = ([sort_field, sort]: SortState) => {
        setUrl({ ...query, sort_field, sort });
    };

    /**
     * Reset the query to load a different status
     *
     * @returns {void}
     */
    const reset: Reset = async () => {
        setPage(0);
        setProspects([]);
        sethasMoreProspects(true);
    };

    useEffect(() => {
        // Reload prospects on query change
        reset();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query]);

    return {
        prospects,
        hasMoreProspects,
        total,
        loadProspects,
        reset,
        order,
        setOrder,
        setProspects,
        view,
        setView,
        loading,
        setLoading,
        error,
        setError,
        setUrl
    };
};

export default useLoadProspects;
