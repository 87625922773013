//type
import { STATUS } from '@interfaces/graphql/API';
import { StatusProps } from '@interfaces/prospects/helpers/layout/Status';

//Library
import React, { ReactElement } from 'react';

/**
 * Create the request card
 *
 * @param {StatusProps} props - The props
 * @returns {ReactElement} - The Timer card
 */
const Status: React.FC<StatusProps> = ({ status }: StatusProps): ReactElement => (
    <>
        {status == STATUS.PENDING_REQUOTE && (
            <div
                aria-label="pending requote"
                className="rounded-full bg-deep-green-100 text-deep-green px-3 py-2 text-xs h-8 ">
                Pending Re-quote
            </div>
        )}
        {status == STATUS.REVIEW && (
            <div
                aria-label="review"
                className="rounded-full bg-azure-100 text-azure px-3 py-2 text-xs h-8 ">
                In Review
            </div>
        )}
        {status == STATUS.PROPOSAL_SEND && (
            <div
                aria-label="proposal sent"
                className="rounded-full bg-cadet-100 text-cadet px-3 py-2 text-xs h-8 ">
                Proposal sent
            </div>
        )}
        {status == STATUS.SHORTLISTED && (
            <div
                aria-label="shortlisted"
                className="rounded-full bg-pale-fushia-100 text-pale-fushia px-3 py-2 text-xs h-8">
                Shortlisted
            </div>
        )}
        {status == STATUS.CLOSE_WON && (
            <div
                aria-label="won"
                className="rounded-full bg-lime-100 text-lime  px-3 py-2 text-xs h-8">
                Close Won
            </div>
        )}
        {status == STATUS.CLOSE_LOST && (
            <div
                aria-label="lost"
                className="rounded-full bg-lavender-100 text-lavender  px-3 py-2 text-xs h-8">
                Close Lost
            </div>
        )}
        {status == STATUS.NURTURE && (
            <div
                aria-label="nurture"
                className="rounded-full bg-rajah-100 text-rajah px-3 py-2 text-xs h-8">
                Nurture
            </div>
        )}
        {status == STATUS.REJECTED && (
            <div
                aria-label="rejected"
                className="rounded-full bg-camel-100 text-camel px-3 py-2 text-xs h-8">
                Rejected
            </div>
        )}
    </>
);

export default Status;
