//type
import { RejectQuoteMutation, STATUS, VIEW } from '@interfaces/graphql/API';
import { ProspectsProps } from '@interfaces/prospects';

//Library
import React, { MouseEvent, ReactElement, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import Scroll from 'react-scroll';
import dynamic from 'next/dynamic';
import { useMedia } from 'react-use';
import { API } from 'aws-amplify';
import { GraphQLResult } from '@aws-amplify/api';

//Code
import useLoadProspects from '@components/prospects/helpers/query/useLoadProspects';
import Menu from '@components/prospects/helpers/layout/Menu';
import Loader from '@components/layout/Loader';
import ProspectsTable from '@components/prospects/helpers/module/prospectsTable/ProspectsTable';
import RejectPopup from '@components/prospects/helpers/layout/RejectPopup';
import { rejectQuote, setView as setViewMutation } from '@components/graphql/mutations';
import useFormatCountryCode from '@components/helpers/useFormatCountryCode';

const ProspectsGrid = dynamic(
    () => import('@components/prospects/helpers/module/prospectsGrid/ProspectGrid'),
    {
        ssr: false
    }
); //Muuri don't work on ssr

/**
 * Prospect page.
 *
 * @param {ProspectsProps} props - The props.
 * @returns {ReactElement} - The propsect page.
 */
const Prospects: React.FC<ProspectsProps> = ({ router }: ProspectsProps): ReactElement => {
    const countryCode = useFormatCountryCode(router.locale);

    const {
        prospects,
        setProspects,
        hasMoreProspects,
        total,
        loadProspects,
        reset,
        order,
        setOrder,
        view,
        setView,
        loading,
        setLoading,
        error,
        setError,
        setUrl
    } = useLoadProspects({ query: router.query, countryCode });

    const [status, setStatus] = useState<STATUS>();
    const isSmallScreen = useMedia('(max-width: 1024px)');

    let scrollParentRef: HTMLElement | null = null;

    const [open, setOpen] = useState(false);
    const [rejectIndex, setRejectIndex] = useState(-1);

    /**
     * Change the status of the query and scroll back to the top of the page
     *
     * @param {STATUS} status - The new status to query
     */
    const fetchStatus = async (status?: STATUS) => {
        setStatus(status);
        reset();
        Scroll.animateScroll.scrollToTop({
            containerId: 'scroll'
        });
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    };

    /**
     * Changes the view between table and grid.
     *
     * @param {VIEW} newView The newly chosen view.
     * @returns {() => Promise<void>} The change view event handler.
     */
    const changeView = (newView: VIEW) => {
        return async () => {
            setView(newView);

            try {
                await API.graphql({
                    query: setViewMutation,
                    variables: {
                        country_code: countryCode,
                        view: newView
                    }
                });
                // eslint-disable-next-line no-empty
            } catch (e) {}
        };
    };

    /**
     * Reject the prospect.
     *
     * @param {string} comment The reject comment.
     */
    const onReject = async (comment: string) => {
        try {
            setLoading(true);
            setOpen(false);

            const response = (await API.graphql({
                query: rejectQuote,
                variables: {
                    country_code: countryCode,
                    id: prospects[rejectIndex].id,
                    index: prospects[rejectIndex].index,
                    comment
                }
            })) as GraphQLResult<RejectQuoteMutation>;

            if (!response.data?.rejectQuote) throw new Error('No response from the server');

            const newProspects = [...prospects];
            newProspects[rejectIndex].status = STATUS.REJECTED;
            setProspects(newProspects);

            setLoading(false);
            setRejectIndex(-1);
            setOpen(false);
        } catch (e) {
            setLoading(false);
            setError('Oops, an error has occurred!!');
            setOpen(true);
        }
    };

    /** Close the pop up. */
    const closePopup = () => {
        setOpen(false);
    };

    /**
     * Creates a open popup function.
     *
     * @param {number} index - Index of the prospect to be rejected.
     * @returns {() => void} - Handler for when user clicks on reject button.
     */
    const openPopup = (index: number) => {
        return (event: MouseEvent<HTMLButtonElement, MouseEvent>) => {
            event.stopPropagation();
            setOpen(true);
            setRejectIndex(index);
            setError('');
        };
    };

    /** Fetches the prospects with given status. */
    const fetchProspects = () => {
        loadProspects(status);
    };

    return (
        <div aria-label="main" className="pt-16">
            <RejectPopup open={open} closeModal={closePopup} onConfirm={onReject} error={error} />
            <div
                aria-label={'results'}
                id="scroll"
                ref={(ref) => (scrollParentRef = ref)}
                className="h-screen w-full ">
                <Menu
                    fetch={fetchStatus}
                    status={status}
                    isTableView={view === VIEW.TABLE}
                    changeView={changeView}
                    setUrl={setUrl}
                    query={router.query}
                />
                {loading && (
                    <div
                        className="px-6 fixed z-40 backdrop-blur-sm bg-white/30 h-screen w-screen"
                        aria-label="loading">
                        <div className="flex justify-center items-center h-screen w-full">
                            <Loader />
                        </div>
                    </div>
                )}
                <InfiniteScroll
                    pageStart={0}
                    loadMore={fetchProspects}
                    hasMore={hasMoreProspects}
                    loader={
                        <div key={0}>
                            <Loader />
                        </div>
                    }
                    getScrollParent={() => scrollParentRef}
                    className="pt-52 md:pt-28 lg:pt-28 xl:pt-20 px-6 pb-20">
                    {/* No prospects */}
                    {total === null && !hasMoreProspects ? (
                        <div className="flex justify-center items-center text-gray-500">
                            We couldn&apos;t find any matching results.
                        </div>
                    ) : view === VIEW.GRID || isSmallScreen ? (
                        <ProspectsGrid
                            prospects={prospects}
                            openPopup={openPopup}
                            loadProspects={fetchProspects}
                        />
                    ) : (
                        <ProspectsTable
                            prospects={prospects}
                            setOrder={setOrder}
                            order={order}
                            openPopup={openPopup}
                        />
                    )}
                </InfiniteScroll>
                {/* Bottom bar for prospect count */}
                <div className="fixed bottom-0 bg-white h-16 shadow-bootomCardList flex justify-center items-center w-full">
                    <div className="text-lg">
                        {total !== null && (
                            <>
                                <span className="font-bold hidden md:inline">
                                    {prospects.length}&nbsp;
                                </span>
                                <span className="text-gray-500 hidden md:inline"> / </span>
                                <span className="text-gray-500">{total}</span>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Prospects;
