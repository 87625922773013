//type
import { listProspectsDataProps } from '@interfaces/prospects/helpers/query/listProspectData';
import {
    ListProspectsQueryAndView,
    VIEW,
    ListProspectsQueryVariables
} from '@interfaces/graphql/API';

// library
import { GraphQLResult } from '@aws-amplify/api';

//code
import { listProspectsAndView } from '@components/graphql/queries';

/**
 * Get the prospects data and preferred view.
 *
 * @param {listProspectsDataProps} props - The api call arguments.
 * @returns {ListProspectsQueryAndView} - The data.
 */
const listProspectsDataAndView = async ({
    API,
    query
}: listProspectsDataProps): Promise<ListProspectsQueryAndView> => {
    const { sort_field = null, sort = null } = query;

    const variables: ListProspectsQueryVariables = {
        ...query,
        sort_field,
        sort
    };

    const response: GraphQLResult<ListProspectsQueryAndView> = (await API.graphql({
        query: listProspectsAndView,
        variables: { ...variables, from: 0, size: 10 }
    })) as GraphQLResult<ListProspectsQueryAndView>;

    return (
        response.data || {
            listProspects: {
                __typename: 'ListProspects',
                total: 0,
                prospects: []
            },
            getProfile: {
                view: VIEW.TABLE
            }
        }
    );
};

export default listProspectsDataAndView;
