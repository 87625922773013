//types
import { SearchInputProps } from '@interfaces/prospects/helpers/layout/SearchInput';

//library
import React, { ReactElement } from 'react';
import { useField } from 'formik';

/**
 * The search input.
 *
 * @param {SearchInputProps} props - The props
 * @returns {ReactElement} - The search input.
 */
const SearchInput: React.FC<SearchInputProps> = ({
    field,
    setUrl
}: SearchInputProps): ReactElement => {
    const [, meta, helpers] = useField({
        name: field
    });

    const { value } = meta;
    const { setValue } = helpers;

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.value);
    };

    return (
        <div
            aria-label="search-bar"
            className="flex items-center w-full self-start border border-gray-300 shadow rounded-3xl h-9 pl-3 py-1 focus:outline-none focus:shadow-outline bg-white">
            <input
                aria-label="search-input"
                placeholder="Search..."
                className="w-9/12 h-5/6 p-1 focus:outline-none focus:shadow-outline appearance-none bg-white"
                value={value}
                onChange={handleChange}
                type="text"
            />
            {value && (
                <button
                    aria-label="search-reset"
                    type="reset"
                    onClick={() => setUrl()}
                    className="mr-4">
                    &#10005;
                </button>
            )}
            <button
                aria-label="search-submit"
                type="submit"
                className="ml-auto bg-elements hover:shadow-outline text-white rounded-3xl h-9 w-16 data-hj-allow">
                <svg
                    className="block mx-auto"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M19 19L13 13M15 8C15 8.91925 14.8189 9.82951 14.4672 10.6788C14.1154 11.5281 13.5998 12.2997 12.9497 12.9497C12.2997 13.5998 11.5281 14.1154 10.6788 14.4672C9.82951 14.8189 8.91925 15 8 15C7.08075 15 6.1705 14.8189 5.32122 14.4672C4.47194 14.1154 3.70026 13.5998 3.05025 12.9497C2.40024 12.2997 1.88463 11.5281 1.53284 10.6788C1.18106 9.82951 1 8.91925 1 8C1 6.14348 1.7375 4.36301 3.05025 3.05025C4.36301 1.7375 6.14348 1 8 1C9.85652 1 11.637 1.7375 12.9497 3.05025C14.2625 4.36301 15 6.14348 15 8Z"
                        stroke="white"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            </button>
        </div>
    );
};

export default SearchInput;
