//type
import { SORT } from '@interfaces/graphql/API';
import { SortButtonProps } from '@interfaces/prospects/helpers/module/prospectsTable/helpers/SortButton';

//library
import React, { ReactElement } from 'react';
import Scroll from 'react-scroll';

/**
 * Button to click and sort.
 *
 * @param {SortButtonProps} props - The sort button props.
 * @returns {ReactElement} - The sort button.
 */
const SortButton: React.FC<SortButtonProps> = ({
    name,
    field,
    order,
    setOrder
}: SortButtonProps): ReactElement => {
    /** Change the order of the query and scroll back to the top of the page */
    const sort = () => {
        switch (true) {
            case field !== order[0]:
                setOrder([field, SORT.ASC]);
                break;
            case field === order[0] && SORT.ASC === order[1]:
                setOrder([field, SORT.DESC]);
                break;
            default:
                setOrder([undefined, undefined]);
                break;
        }

        Scroll.animateScroll.scrollToTop({
            containerId: 'scroll'
        });
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    };

    return (
        <button
            type="button"
            className={`
                flex items-center focus:outline-none my-auto 
                 hover:text-elements active:outline-none py-5 
                ${order[0] === field ? 'text-navy' : 'text-gray-500'}
            `}
            onClick={sort}>
            <div className={`pr-3 truncate xl:w-full `}>{name}</div>
            {field && (
                <div
                    aria-label="handle"
                    className={`transform transition-transform ease-in-out duration-500
                    ${order[0] !== field && '-rotate-90'}
                    ${order[0] === field && order[1] == 'ASC' && '-rotate-180'}
                    ${order[0] === field && order[1] == 'DESC' && 'rotate-0'}`}>
                    <svg
                        className="stroke-current"
                        width="16"
                        height="9"
                        viewBox="0 0 16 9"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M15 1L8 8L1 1"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                </div>
            )}
        </button>
    );
};

export default SortButton;
