/* tslint:disable */
/* eslint-disable */
// This file extends the amplify-generated queries file
// and customizes the fields called
// (copy over and remove unneccessary fields)

export * from '@components/graphql/generated/queries';

export const listProspects = /* GraphQL */ `
    query ListProspects(
        $country_code: COUNTRY_CODE!
        $sort: SORT
        $sort_field: PROSPECT_SORT_FIELD
        $status: STATUS
        $from: Int!
        $size: Int!
        $search_term: String
    ) {
        listProspects(
            country_code: $country_code
            sort: $sort
            sort_field: $sort_field
            status: $status
            from: $from
            size: $size
            search_term: $search_term
        ) {
            total
            prospects {
                id
                timeframe
                address
                postal_code
                type
                expected_date
                occupancy
                docs {
                    link
                    name
                    type
                    category
                }
                remark
                status
                created
                delivery
                index
                comment
            }
        }
    }
`;

export const listProspectsAndView = /* GraphQL */ `
    query ListProspects(
        $country_code: COUNTRY_CODE!
        $sort: SORT
        $sort_field: PROSPECT_SORT_FIELD
        $status: STATUS
        $from: Int!
        $size: Int!
        $search_term: String
    ) {
        listProspects(
            country_code: $country_code
            sort: $sort
            sort_field: $sort_field
            status: $status
            from: $from
            size: $size
            search_term: $search_term
        ) {
            total
            prospects {
                id
                timeframe
                address
                postal_code
                type
                expected_date
                occupancy
                docs {
                    link
                    name
                    type
                    category
                }
                remark
                status
                created
                delivery
                index
                comment
            }
        }
        getProfile(country_code: $country_code) {
            view
        }
    }
`;
